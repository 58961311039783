@font-face {
  font-family: "SuisseIntl";
  src: url("../fonts/SuisseIntl-Regular.ttf");
}

body,
html {
  font-family: "SuisseIntl", "Helvetica", sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

#stage {
  position: fixed;
}

main {
  padding: 3em;
  position: relative;
  display: grid;
  place-items: start;
  color: white;
}

h1 {
  font-size: 2.5em;
}

h3 {
  font-weight: light;
  width: 70%;
  max-width: 800px;
}

.blur {
  /* backdrop-filter: blur(3px);
  height: 40vh; */
}

hr {
  float: left;
  width: 70%;
  max-width: 800px;
  border-top: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
